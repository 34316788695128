<template>
  <div class="container-fluid">
    <purchasesPayment-form
      :loading="loading"
      :purchasesPaymentData="purchasesPayment"
      :formErrors="formErrors"
      @purchasesPaymentSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultPurchasesPayment from "../defaultPurchasesPayment";
import PurchasesPaymentForm from "../partials/PurchasesPaymentForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PurchasesPaymentForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      purchasesPayment: cloneDeep(defaultPurchasesPayment),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(purchasesPaymentData) {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "purchasesPayments/add",
          purchasesPaymentData
        );
        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_PAYMENTS.PURCHASES_PAYMENT_ADDED"),
        });
        const purchasesPayment = await this.$store.getters[
          "purchasesPayments/purchasesPayment"
        ];
        this.$emit("onViewPurchasesPayment", purchasesPayment);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
