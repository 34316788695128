<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!purchasesPayment">
      <span class="loader"></span>
    </span>
    <purchasesPayment-form
      v-if="purchasesPayment"
      :loading="loading"
      :purchasesPaymentData="purchasesPayment"
      :formErrors="formErrors"
      @purchasesPaymentSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import PurchasesPaymentForm from "../partials/PurchasesPaymentForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { PurchasesPaymentForm },

  mixins: [alertLeave],

  props: {
    purchasesPaymentId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      purchasesPayment: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "purchasesPayments/get",
          this.purchasesPaymentId
        );
        this.purchasesPayment =
          this.$store.getters["purchasesPayments/purchasesPayment"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(purchasesPayment) {
      this.loading = true;

      const purchasesPaymentData = cloneDeep(purchasesPayment);

      try {
        await this.$store.dispatch(
          "purchasesPayments/update",
          purchasesPaymentData
        );
        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_PAYMENTS.PURCHASES_PAYMENTS_UPDATED"),
        });
        const purchasesPayment = await this.$store.getters[
          "purchasesPayments/purchasesPayment"
        ];
        this.$emit("onViewPurchasesPayment", purchasesPayment);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
